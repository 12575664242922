import { STATE } from "@core/state";
import { $$, body, html } from "@utils/dom";
import { on, off } from "@utils/listener";
import Viewport from "@utils/viewport";
import { inViewport, moduleDelays } from "./utils";

const CLASSNAME = "--js-windmill-transition";
const ENTER = "--js-windmill-transition-running";

class SiteTransition {
  constructor() {
    this._enterResolve = null;
    this._nextContainer = null;
    this._transitionDelay = 0;

    this._onEnterCompleted = this._onEnterCompleted.bind(this);
  }

  exiting({ current }) {
    this._transitionDelay = 0;

    if (STATE.siteNavOpen) {
      this._transitionDelay = 450;
      this._transitionDelay *= Viewport.width < 768 ? 2 : 1;
    }

    if (STATE.siteSearchOpen) {
      if (Viewport.width < 1200) {
        this._transitionDelay = 450;
      }
    }
    this._transitionStartTime = Date.now();

    const { container } = current;

    // set body height to prevent layout shift
    body.style.height = `${body.scrollHeight}px`;
    html.style.cursor = 'wait';

    // remove all [data-scroll] from container
    [ ...$$('[data-scroll]', container) ].forEach(el => el.removeAttribute('data-scroll'));

    // remove all [data-module] & [data-ui] from container
    [ ...$$('[data-module]', container) ].forEach(el => el.removeAttribute('data-module'));
    [ ...$$('[data-ui]', container) ].forEach(el => el.removeAttribute('data-ui'));

    // save container's background-color
    const bgColor = getComputedStyle(container).backgroundColor;
    container.style.backgroundColor = bgColor;

    // prepare current container for transition
    current.container.setAttribute('aria-hidden', true);
  }

  // this method is required for the transition to be selected by Windmill
  exit() { html.classList.add(CLASSNAME); }

  fetched({ current, next }) {
    current.container.style.setProperty('--scroll-y', `${window.scrollY}px`);
    next.container.classList.add('visibility-hidden', 'position-relative', 'z-2');
  }

  entering({ next }) {
    // remove [data-scroll] & [data-module-delay] attributes from elements that are in viewport during page transition
    [ ...$$('[data-scroll][data-scroll-transition-remove]', next.container) ].forEach(element => {
      if( !inViewport(element) ) return;

      element.removeAttribute('data-scroll');
      element.removeAttribute('data-module-delay');
    });

    moduleDelays(350, 550, next.container);
  }
  enter({ next }) {
    return new Promise((resolve) => {
      // set body height to prevent layout shift
      body.style.height = `${body.scrollHeight}px`;
      next.container.parentElement.style.overflow = 'hidden';
      html.style.removeProperty('cursor');

      this._enterResolve = resolve;
      this._nextContainer = next.container;

      on(this._nextContainer, 'animationend', this._onEnterCompleted);

      // calculate how much time as passed since transition started
      // remove that time from initial delay
      const timestamp = Math.max(0, this._transitionDelay - (Date.now() - this._transitionStartTime));

      // apply transition delay & start animation
      next.container.parentElement.style.setProperty('--transition-delay', `${timestamp}ms`);
      html.classList.add(ENTER);
    });
  }
  entered({ current, next }) {
    current.container.remove();
    next.container.classList.remove('position-relative', 'z-2');
    next.container.parentElement.style.removeProperty('overflow');
    body.style.removeProperty('height');
  }

  _onEnterCompleted(event) {
    if( event.target !== this._nextContainer ) return;

    off(this._nextContainer, 'animationend', this._onEnterCompleted);
    this._nextContainer.classList.remove('visibility-hidden');
    html.classList.remove(ENTER);

    this._enterResolve();

    this._nextContainer = null;
    this._enterResolve = null;
  }
}

export default SiteTransition;

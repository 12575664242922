export default [
  //"modules/accordions",
  "modules/artist-archives",
  "modules/artist-single-audios",
  "modules/edition-archives",
  //"modules/filtered-posts-list",
  //"modules/google-map",
  //"modules/lottie",
  //"modules/newsletter",
  "modules/pb-row-explore-grid",
  "modules/pb-row-hero",
  //"modules/pb-row-medias",
  //"modules/pb-row-oembed",
  //"modules/pb-row-tabs",
  //"modules/pb-row-testimonials",
  //"modules/pb-row-wrapper-reveal",
  //"modules/prefers-color-scheme-btn",
  //"modules/scrolling-images-sequence",
  "modules/single-gallery",
  "modules/single-medias-viewer",
  "modules/single-videos",
  //"modules/sharing",
  //"modules/svg-path-length",
  //"modules/text-ticker",
  //"modules/video"
];
